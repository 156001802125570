<template>
  <div class="auth-page">
    <b-container>
      <b-card class="widget-auth mx-auto" header-bg-variant="custom">
        <template #header>
          <h4 class="text-white">{{ $t('menu.forgottenPassword') }}</h4>
        </template>
        <p class="widget-auth-info">{{ $t("general.email_sign_up") }}</p>
        <form class="mt" @submit.prevent="submit">
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">{{ errorMessage }}</b-alert>
          <div class="form-group">
            <input
              class="form-control no-border"
              ref="email"
              required
              type="email"
              name="email"
              placeholder="Email"
              v-model="email"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control no-border"
              ref="password"
              required
              type="password"
              name="password"
              v-model="password"
              :placeholder="$t('general.password')"
            />
          </div>
          <div v-if="displayPin" class="mt-2 form-group">
            <h5>
              <font-awesome-icon class="mr-1" :icon="['fab', 'google']" />
              <span v-html="$t('general.pin')" />
            </h5>
            <PincodeInput
              v-model="pin"
              placeholder="0"
              :length="6"
            />
          </div>
          <b-button
            type="submit"
            size="sm"
            class="auth-btn mb-3 bg-custom border-custom"
            variant="custom"
          >{{ $t("general.login") }}</b-button>
        </form>
        <b-row>
          <b-col>
            <p class="widget-auth-info mb-5">{{ $t("general.no_account") }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <router-link class="btn btn-custom bg-custom border-custom" :to="$i18nRoute({ name: 'Dashboard' })">
              <span class="text-light" v-html="$t('menu.backhome')" />
            </router-link>
          </b-col>
          <b-col md="6">
            <router-link class="btn btn-custom bg-custom border-custom" :to="$i18nRoute({ name: 'DemandResetPassword' })">
              <span class="text-light" v-html="$t('menu.forgottenPassword')" />
            </router-link>
          </b-col>
        </b-row>
        <b-row class="mt-3 text-center">
          <b-col>{{ new Date().getFullYear() }} &copy; {{ $t("menu.tourobs") }}.</b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import PincodeInput from 'vue-pincode-input'
import AuthMixin from '@/mixins/api/auth'
export default {
  name: "LoginPage",
  components: {
    PincodeInput,
  },
  mixins: [AuthMixin],
  data() {
    return {
      errorMessage: null,
      email: null,
      password: null,
      pin: null,
      displayPin: false,
    }
  },
  watch: {
    pin(val) {
      if(val.length === 6) this.submit()
    },
  },
  methods: {
    submit() {
      let email = this.email
      let password = this.password
      let pin = this.pin
      this.login({ email, password, pin })
    },
  },
  created() {
    if (window.localStorage.getItem("authenticated") === "true") {
      this.$router.push("/dashboard")
    }
  },
}
</script>
